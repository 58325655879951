import { ApplicationState } from "store/state";
export const getRegion = (state: ApplicationState) => state.region;
export const getClients = (state: ApplicationState) => state.clients;
export const getCommunities = (state: ApplicationState) => state.communities;
export const getSettings = (state: ApplicationState) => state.settings;
export const getCommunitySearches = (state: ApplicationState) =>
    state.communitySearch.searches;
export const getPermissions = (state: ApplicationState) => state.permissions;
export const getUsers = (state: ApplicationState) => state.users;
export const getUserState = (state: ApplicationState) => state.userState;
export const getTenant = (state: ApplicationState) => state.tenant.tenant;
