import {
    AnswerEntityId,
    CustomListCategoryId,
    CustomListItemId,
    CustomListKey,
    IBridgeEntity,
    IBridgeSelect,
    IQuestion,
    Question,
    QuestionFilter,
    findRelatedField,
    formatEntityValue,
} from "@sp-crm/core";
import { graphqlFetcher } from "backend/fetcher";
import { makeGetEntityByIdDocument } from "components/advanced-search/advanced-search-request";
import {
    AdvancedSearchEntityType,
    AdvancedSearchResultEntity,
    BridgeEntityResult,
    GetCustomListQuery,
    GetCustomListsQuery,
    QueryGetEntityByIdArgs,
    useGetCustomListQuery,
    useGetCustomListsQuery,
    useGetEntitiesQuery,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { stringHash } from "util/generic";
import { stableQueryOptions } from "util/requests";
import { useProductName } from "./branding";
import { useRegionId, useRegions, useUsers } from "./hooks";

interface BridgeEntityHook {
    entity: AdvancedSearchResultEntity | null;
    select: IBridgeSelect[];
    formatValue: (select: IBridgeSelect) => string;
    addSelect: (select: IBridgeSelect) => boolean;
}

interface BridgeEntityHookState {
    entity: AdvancedSearchResultEntity | null;
    entityType: AdvancedSearchEntityType;
    entityId: AnswerEntityId;
    entityMetadataList: BridgeEntityResult[];
    entityMetadata: BridgeEntityResult | null;
    select: IBridgeSelect[];
}

const initialState = (
    entityType: AdvancedSearchEntityType,
    entityId: AnswerEntityId,
): BridgeEntityHookState => ({
    entity: null,
    entityMetadataList: [],
    entityMetadata: null,
    select: [],
    entityType,
    entityId,
});

const fieldNamesFromEntity = (entity: IBridgeEntity): string[] => {
    const fields = entity.fields.filter(f => f.reportBehavior.selectable);

    const names: string[] = [];

    fields.forEach(f => {
        names.push(f.name);

        f.aliases?.forEach(a => {
            names.push(a);
        });
    });

    return names;
};

type BridgeEntityHookAction =
    | {
          type: "entitiesLoaded";
          entityTypes: BridgeEntityResult[];
      }
    | {
          type: "entityLoaded";
          entity: AdvancedSearchResultEntity | null;
      }
    | {
          type: "addSelect";
          select: IBridgeSelect[];
      }
    | {
          type: "switchEntityType";
          entityType: AdvancedSearchEntityType;
      };

const reducer: React.Reducer<BridgeEntityHookState, BridgeEntityHookAction> = (
    p,
    a,
): BridgeEntityHookState => {
    return produce(p, draft => {
        if (a.type === "entitiesLoaded") {
            draft.entityMetadataList = a.entityTypes;
            const entityMetadata =
                a.entityTypes.find(e => e.name === draft.entityType) || null;
            draft.entityMetadata = entityMetadata;
            if (entityMetadata && draft.select.length === 0) {
                draft.select = fieldNamesFromEntity(entityMetadata).map(f => ({
                    fieldName: f,
                }));
            }
        }

        if (a.type === "entityLoaded") {
            draft.entity = a.entity;
        }

        if (a.type === "addSelect") {
            a.select.forEach(s => {
                if (!draft.select.find(ss => ss.fieldName === s.fieldName)) {
                    draft.select.push(s);
                }
            });
        }

        if (a.type === "switchEntityType") {
            draft.entityType = a.entityType;
            const entityMetadata =
                draft.entityMetadataList.find(e => e.name === draft.entityType) || null;
            draft.entityMetadata = entityMetadata;
            draft.entity = null;
            draft.select = [];
            if (entityMetadata) {
                draft.select = fieldNamesFromEntity(entityMetadata).map(f => ({
                    fieldName: f,
                }));
            }
        }
    });
};

interface DynamicGetEntityByIdQuery {
    getEntityById: {
        entity: AdvancedSearchResultEntity | null;
    };
}

export const useBridgeEntity = (
    entityType: AdvancedSearchEntityType,
    entityId: AnswerEntityId,
): BridgeEntityHook => {
    const [state, dispatch] = React.useReducer(
        reducer,
        initialState(entityType, entityId),
    );
    const getEntitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());

    useEffect(() => {
        if (getEntitiesQuery.data?.getEntities?.length > 0) {
            dispatch({
                type: "entitiesLoaded",
                entityTypes: getEntitiesQuery.data.getEntities,
            });
        }
    }, [getEntitiesQuery.data?.getEntities]);

    useEffect(() => {
        if (state.entityType !== entityType) {
            dispatch({
                type: "switchEntityType",
                entityType,
            });
        }
    }, [entityType]); // eslint-disable-line react-hooks/exhaustive-deps

    const getEntityByIdDocument = React.useMemo(() => {
        if (state.select.length > 0) {
            return makeGetEntityByIdDocument(
                state.entityMetadata,
                state.select,
                state.entityMetadataList,
            );
        }

        return "";
    }, [state.select, state.entityMetadata, state.entityMetadataList]);

    const getEntityByIdQuery = useQuery<DynamicGetEntityByIdQuery>(
        [
            "getEntityById",
            {
                entityId,
                entityType,
                select: state.select,
                documentHash: stringHash(getEntityByIdDocument),
            },
        ],
        graphqlFetcher<DynamicGetEntityByIdQuery, QueryGetEntityByIdArgs>(
            getEntityByIdDocument,
            {
                entityType,
                entityId,
                select: state.select,
            },
        ),
        {
            enabled: false,
            retry: false,
            cacheTime: 0,
        },
    );

    useEffect(() => {
        if (state.select.length > 0) {
            getEntityByIdQuery.refetch();
        }
    }, [state.select]); // eslint-disable-line react-hooks/exhaustive-deps

    const retrievedEntity = getEntityByIdQuery.data?.getEntityById.entity || null;

    useEffect(() => {
        if (retrievedEntity) {
            dispatch({
                type: "entityLoaded",
                entity: retrievedEntity,
            });
        }
    }, [retrievedEntity]);

    const regions = useRegions();
    const users = useUsers();
    const productName = useProductName();

    const formatValue = useCallback(
        (select: IBridgeSelect) => {
            const fieldDefinition = findRelatedField(
                select.fieldName,
                state.entityMetadata,
                state.entityMetadataList,
            );

            if (fieldDefinition) {
                return formatEntityValue(
                    state.entity,
                    select.fieldName,
                    fieldDefinition.metadata,
                    {
                        regions,
                        users: users.users,
                        productName,
                    },
                );
            }

            return "";
        },
        [
            state.entity,
            state.entityMetadata,
            state.entityMetadataList,
            regions,
            users,
            productName,
        ],
    );

    const addSelect = useCallback(
        (select: IBridgeSelect): boolean => {
            if (state.select.find(s => s.fieldName === select.fieldName)) {
                return true;
            }

            const fieldDefinition = findRelatedField(
                select.fieldName,
                state.entityMetadata,
                state.entityMetadataList,
            );

            if (!fieldDefinition) {
                return false;
            }

            const parts = select.fieldName.split(".");
            const partsWithoutLast = parts.slice(0, parts.length - 1);

            const fieldsToAdd = fieldNamesFromEntity(fieldDefinition.entityMetadata).map(
                f => ({
                    fieldName: [...partsWithoutLast, f].join("."),
                }),
            );

            dispatch({
                type: "addSelect",
                select: fieldsToAdd,
            });
            return true;
        },
        [state.select, state.entityMetadata, state.entityMetadataList],
    );

    return {
        entity: state.entity,
        select: state.select,
        formatValue,
        addSelect,
    };
};

export const useBridgeTokens = (
    entityType: AdvancedSearchEntityType,
    entityId: AnswerEntityId,
) => {
    const { entity, select, formatValue, addSelect } = useBridgeEntity(
        entityType,
        entityId as AnswerEntityId,
    );

    const tokenReplacements: Record<string, string> = React.useMemo(() => {
        return select.reduce((acc, curr) => {
            if (curr.fieldName) {
                const tokenValue = `${entityType.toLowerCase()}.${curr.fieldName}`;
                acc[tokenValue] = formatValue(curr);
            }
            return acc;
        }, {} as Record<string, string>);
    }, [entity, entityType]); // eslint-disable-line react-hooks/exhaustive-deps

    const addToken = React.useCallback(
        (tokenValue: string) => {
            const parts = tokenValue.split(".");

            if (parts[0].toLowerCase() === entityType.toLowerCase()) {
                return addSelect({ fieldName: parts.slice(1).join(".") });
            }

            return false;
        },
        [entityType, addSelect],
    );

    return {
        tokenReplacements,
        addToken,
    };
};

export const useBridgeTypes = () => {
    const bridgeEntityTypes = useGetEntitiesQuery({}, stableQueryOptions());

    const entityTypes = React.useMemo(() => {
        return bridgeEntityTypes.data?.getEntities || [];
    }, [bridgeEntityTypes.data?.getEntities]);

    const status = React.useMemo(() => {
        if (bridgeEntityTypes.isLoading) {
            return "loading";
        }

        if (bridgeEntityTypes.isError) {
            return "error";
        }

        return "ready";
    }, [bridgeEntityTypes.isLoading, bridgeEntityTypes.isError]);

    return { entityTypes, status };
};

export const useBridgeEntityType = (entityName: AdvancedSearchEntityType) => {
    const { entityTypes, status: bridgeTypesStatus } = useBridgeTypes();

    const entityType = React.useMemo(() => {
        return entityTypes.find(e => e.name === entityName) || null;
    }, [entityTypes, entityName]);

    const status = React.useMemo(() => {
        if (bridgeTypesStatus !== "ready") {
            return bridgeTypesStatus;
        }

        if (!entityType) {
            return "notfound";
        }

        return "ready";
    }, [entityType, bridgeTypesStatus]);

    return {
        entityType,
        allTypes: entityTypes,
        status,
    };
};

export const useBridgeQuestions = (entityName: AdvancedSearchEntityType): IQuestion[] => {
    const { entityType } = useBridgeEntityType(entityName);

    const questions = React.useMemo(() => {
        if (entityType) {
            return entityType.questions.map(q => Question.load(q));
        }

        return [];
    }, [entityType]);

    return questions;
};

export const useBridgeQuestionsForCurrentRegion = (
    entityName: AdvancedSearchEntityType,
): IQuestion[] => {
    const questions = useBridgeQuestions(entityName);
    const regions = useRegions();
    const regionId = useRegionId();
    const region = regions.find(r => r.id === regionId);
    const regionKey = region?.key;

    const regionalQuestions = React.useMemo(() => {
        if (!regionKey) {
            return questions;
        }

        return QuestionFilter.filter(questions, regionKey, regionId);
    }, [questions, regionKey, regionId]);

    return regionalQuestions;
};

export const useBridgeQuestionsForRegions = (
    entityName: AdvancedSearchEntityType,
    regionKeys: string[],
) => {
    const questions = useBridgeQuestions(entityName);
    const regions = useRegions();

    const regionIds = React.useMemo(() => {
        return regions.filter(r => regionKeys.includes(r.key)).map(r => r.id);
    }, [regionKeys, regions]);

    const regionalQuestions = React.useMemo(() => {
        if (regionKeys.length === 0) {
            return questions;
        }

        return QuestionFilter.filterAll(questions, regionKeys, regionIds);
    }, [questions, regionKeys, regionIds]);

    return regionalQuestions;
};

export const useCustomList = (
    key: CustomListKey,
): {
    customList: GetCustomListQuery["getCustomList"];
    status: "loading" | "error" | "ready";
    getListItemName: (idOrKey: CustomListItemId | string) => string;
    getListItemKey: (id: CustomListItemId) => string;
    compareListItems: (a: CustomListItemId, b: CustomListItemId) => number;
    itemHasCategory: (
        itemIdOrKey: CustomListItemId | string,
        categoryIdOrKey: CustomListCategoryId | string,
    ) => boolean;
    getListItem: (
        idOrKey: CustomListItemId | string,
    ) => GetCustomListQuery["getCustomList"]["items"][0] | null;
    refetch: () => void;
} => {
    const { data, isLoading, isError, refetch } = useGetCustomListQuery(
        {
            key,
        },
        stableQueryOptions(),
    );

    const customList = React.useMemo(() => {
        if (data?.getCustomList) {
            return data.getCustomList;
        }

        return null;
    }, [data?.getCustomList]);

    const status = React.useMemo(() => {
        if (isLoading) {
            return "loading";
        }

        if (isError) {
            return "error";
        }

        return "ready";
    }, [isLoading, isError]);

    const getListItem = useCallback(
        (idOrKey: CustomListItemId | string) => {
            if (!idOrKey) {
                return null;
            }

            return (
                customList?.items.find(x => x.id === idOrKey || x.key === idOrKey) || null
            );
        },
        [customList],
    );

    const getListItemName = useCallback(
        (idOrKey: CustomListItemId | string) => {
            const item = getListItem(idOrKey);
            return item?.name || "";
        },
        [getListItem],
    );

    const getListItemOrder = useCallback(
        (idOrKey: CustomListItemId | string) => {
            const item = getListItem(idOrKey);
            return item?.order ?? 1000;
        },
        [getListItem],
    );

    const getListItemKey = useCallback(
        (id: CustomListItemId) => {
            const item = getListItem(id);
            return item?.key || "__unknown__";
        },
        [getListItem],
    );

    const compareListItems = useCallback(
        (a: CustomListItemId, b: CustomListItemId) => {
            return getListItemOrder(a) - getListItemOrder(b);
        },
        [getListItemOrder],
    );

    const getCategory = useCallback(
        (idOrKey: CustomListCategoryId | string) => {
            return (
                customList?.categories.find(c => c.id === idOrKey || c.key === idOrKey) ||
                null
            );
        },
        [customList],
    );

    const itemHasCategory = useCallback(
        (
            itemKeyOrId: CustomListItemId | string,
            categoryKeyOrId: CustomListCategoryId | string,
        ) => {
            const item = getListItem(itemKeyOrId);
            const category = getCategory(categoryKeyOrId);

            if (!item || !category) {
                return false;
            }

            return item.customListCategoryId === category.id;
        },
        [getListItem, getCategory],
    );

    return {
        customList,
        status,
        getListItemName,
        getListItemKey,
        compareListItems,
        itemHasCategory,
        getListItem,
        refetch,
    };
};

export const useCustomLists = (): {
    customLists: GetCustomListsQuery["getCustomLists"];
    status: "loading" | "error" | "ready";
} => {
    const { data, isLoading, isError } = useGetCustomListsQuery({}, stableQueryOptions());

    const customLists = React.useMemo(() => {
        if (data?.getCustomLists) {
            return data.getCustomLists;
        }

        return [];
    }, [data?.getCustomLists]);

    const status = React.useMemo(() => {
        if (isLoading) {
            return "loading";
        }

        if (isError) {
            return "error";
        }

        return "ready";
    }, [isLoading, isError]);

    return {
        customLists,
        status,
    };
};
