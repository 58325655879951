import React from "react";
import ReactDOM from "react-dom/client";
import { breakNewlines } from "util/text";
import { Alert, AlertProps } from "./dialog/alert";

export const fancyAlert = async (
    title: string,
    prompt: string,
    type: AlertProps["type"] = "warning",
): Promise<void> => {
    const wrapper = document.body.appendChild(document.createElement("div"));
    const root = ReactDOM.createRoot(wrapper);
    const promise = new Promise<void>(resolve => {
        const ok = () => resolve();

        root.render(
            <Alert title={title} onDismiss={ok} type={type}>
                <p>{breakNewlines(prompt)}</p>
            </Alert>,
        );
    });
    await promise;
    root.unmount();
    wrapper.remove();
};
