import * as Common from "@sp-crm/core";
import { ActionType, Maybe, PermissionMapping, UserId } from "@sp-crm/core";
import * as _ from "lodash";
import { createSelector } from "reselect";
import { PermissionsState } from "../reducers/permissions";
import { RegionState } from "../reducers/region";
import { SingleUserState } from "../reducers/user";
import { UserState } from "../reducers/users";
import {
    getPermissions,
    getRegion,
    getTenant,
    getUserState,
    getUsers,
} from "./selectors";

export type UserMap = Record<UserId, Common.User>;

export const usersForCurrentRegion = createSelector(
    getUsers,
    getTenant,
    getRegion,
    (userState: UserState, tenant: Common.ITenant, region: RegionState): UserMap => {
        const userIds = Maybe.fromValue(region)
            .map(r => r.selectedRegion)
            .map(selectedRegion =>
                tenant.regions.find(tenantRegion => tenantRegion.key === selectedRegion),
            )
            .map(tenantRegion => tenantRegion.users)
            .getOrElse(_.keys(userState.users) as UserId[]);
        const returnValue: UserMap = {};
        _.each(userIds, id => {
            const user = userState.users[id];
            if (user) returnValue[id] = user;
        });
        return returnValue;
    },
);

export const usersForAllRegionsAccordingToLoggedInUserPermissions = createSelector(
    getRegion,
    getPermissions,
    getUserState,
    getUsers,
    (
        regionState: RegionState,
        permissionsState: PermissionsState,
        singleUserState: SingleUserState,
        userState: UserState,
    ): UserMap => {
        const regionsWithPermissions: PermissionMapping[] = Maybe.fromValue(
            permissionsState,
        )
            .map(p => p.permissions)
            .map(p => p.regions)
            .getOrElse([]);

        const userIds = new Set<UserId>();

        for (const region of regionState.regions) {
            const canViewAllClients = regionsWithPermissions.find(
                r =>
                    r.regionKey === region.key &&
                    r.permissions.includes(ActionType.LoadClients),
            );

            if (canViewAllClients) {
                region.users.forEach(u => {
                    userIds.add(u);
                });
            } else {
                userIds.add(singleUserState.userId);
            }
        }

        const result: UserMap = {};

        userIds.forEach(userId => {
            result[userId] = userState.users[userId];
        });

        return result;
    },
);

export const usersForRegionId = createSelector(
    getRegion,
    getPermissions,
    getUserState,
    getUsers,
    (
            regionState: RegionState,
            permissionsState: PermissionsState,
            singleUserState: SingleUserState,
            userState: UserState,
        ) =>
        (regionId: Common.RegionId): UserMap => {
            const region = regionState.regions.find(r => r.id === regionId);

            const regionsWithPermissions: PermissionMapping[] = Maybe.fromValue(
                permissionsState,
            )
                .map(p => p.permissions)
                .map(p => p.regions)
                .getOrElse([]);

            const userIds = new Set<UserId>();

            if (region) {
                const canViewAllClients = regionsWithPermissions.find(
                    r =>
                        r.regionKey === region.key &&
                        r.permissions.includes(ActionType.LoadClients),
                );

                if (canViewAllClients) {
                    region.users.forEach(u => {
                        userIds.add(u);
                    });
                } else {
                    userIds.add(singleUserState.userId);
                }
            }

            const result: UserMap = {};

            userIds.forEach(userId => {
                result[userId] = userState.users[userId];
            });

            return result;
        },
);
