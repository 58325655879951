export enum LoadingState {
    NOTSTARTED,
    LOADING,
    ERROR,
    DONE,
}

export enum RequestState {
    NOTSTARTED,
    REQUESTED,
    ERROR,
    DONE,
}
