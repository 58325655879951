import * as React from "react";

export const NO_NAME = "(no name)";
export function friendlyDisplayName(
    inputName: string,
    placeholder: string = NO_NAME,
): string {
    if (!inputName) {
        return placeholder;
    }
    return inputName.trim().length > 0 ? inputName.trim() : placeholder;
}

const style: React.CSSProperties = {
    wordBreak: "break-word",
};

export function breakNewlines(input: string): JSX.Element {
    if (!input || input === "") {
        return <span />;
    }
    const chunks = input.split("\n").map((text, index) => {
        if (index === 0) {
            return (
                <span style={style} key={index}>
                    {text}
                </span>
            );
        }
        return (
            <span style={style} key={index}>
                <br />
                {text}
            </span>
        );
    });
    return <span>{chunks}</span>;
}

export function truncate(input: string, length = 140): string {
    if (!input) {
        return null;
    }

    if (input.length <= length) {
        return input;
    }

    return `${input.substring(0, length - 1)}…`;
}
