import React from "react";

export const secondaryClasses =
    "inline-flex justify-center py-2 px-4 border border-2 border-gray-300 shadow-sm  text-base font-medium rounded-md text-gray-800 bg-white " +
    "hover:bg-gray-200 hover:text-black " +
    "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 " +
    "disabled:bg-gray-100 disabled:text-gray-300";

const SecondaryButton = React.forwardRef<
    HTMLButtonElement,
    React.ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => {
    const { className, ...rest } = props;
    const extendedClassName = `${className ?? ""} ${secondaryClasses}`;
    return <button ref={ref} className={extendedClassName} {...rest} />;
});

SecondaryButton.displayName = "SecondaryButton";
export { SecondaryButton };
