import React from "react";

interface Props {
    label?: string | JSX.Element;
    textSize?: "sm" | "base";
}

// As of October, 2021, preference is to use SelectPro which wraps this control
// rather than use this Select control directly.
export const Select: React.FC<
    Props & React.SelectHTMLAttributes<HTMLSelectElement>
> = props => {
    const { label, className, textSize, ...rest } = props;

    let textSizeClassName = "";

    switch (textSize) {
        case "sm":
            textSizeClassName = "text-sm";
            break;
    }

    return (
        <div className={`${className ?? ""}`}>
            <label>
                {label ? (
                    <div className={`mb-1 ${textSizeClassName}`}>{label}</div>
                ) : null}
                <select
                    className={`form-select rounded-sm md:rounded w-full disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300 ${textSizeClassName}`}
                    {...rest}
                />
            </label>
        </div>
    );
};
