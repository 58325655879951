import { ActionType, locales, Localization, Maybe } from "@sp-crm/core";
import { useGetEntityRegionPermissionsQuery } from "generated/graphql";
import { createSelector } from "reselect";
import { stableQueryOptions } from "util/requests";
import { RegionState } from "../reducers/region";
import { getRegion } from "./selectors";

export const usesMoreThanOneRegion = createSelector(getRegion, (region: RegionState) => {
    const { regions } = region;
    return regions.length > 1;
});

export const currentLocale = createSelector(
    getRegion,
    (region: RegionState): Localization => {
        const localeName = Maybe.fromValue(region.selectedRegion)
            .map(key => region.regions.find(r => r.key === key))
            .map(r => r.locale)
            .getOrElse("default");
        return locales[localeName] || locales.default;
    },
);

interface UseEntityRegionsOptions {
    actionType?: ActionType;
    enabled?: boolean;
}

export const useEntityRegions = (options: UseEntityRegionsOptions = {}) => {
    const { actionType = ActionType.Noop, enabled = true } = options;

    const entityRegionPermissions = useGetEntityRegionPermissionsQuery(
        { params: { actionType } },
        {
            ...stableQueryOptions(),
            enabled,
        },
    );

    return (
        entityRegionPermissions?.data?.getEntityRegionPermissions?.editableRegions || []
    );
};
