import { ILocation, RegionId, SettingsCommunitySortType } from "@sp-crm/core";
import { QueryClient } from "react-query";
import { Action } from "redux";
import { CommunitySearchInstance } from "store/reducers/community-search";
import { Actions } from "./index";

export const invalidateCommunityQueryCache = (queryClient: QueryClient) => {
    queryClient.invalidateQueries("CommunitySearch");
};

export type CommunitySearchAction = {
    key: string;
} & Action;

export type CommunitySearchGoToPageAction = {
    pageNumber: number;
} & CommunitySearchAction;

export type CommunitySearchSetPageSizeAction = {
    pageSize: number;
} & CommunitySearchAction;

export type CommunitySearchMapMoveAction = {
    northWest: ILocation;
    southEast: ILocation;
} & CommunitySearchAction;

export type CommunitySearchUpdateSearchAction = {
    searchInstance: CommunitySearchInstance;
} & CommunitySearchAction;

export type CommunitySearchInitializeAction = {
    sort: SettingsCommunitySortType;
    regionId: RegionId;
    searchInstance?: CommunitySearchInstance;
} & CommunitySearchAction;

export const communitySearchMapMoveEvent = (
    key: string,
    northWest: ILocation,
    southEast: ILocation,
): CommunitySearchMapMoveAction => ({
    type: Actions[Actions.COMMUNITY_SEARCH_MAP_MOVE],
    northWest,
    southEast,
    key,
});

export const communitySearchInitializeEvent = (
    key: string,
    sort: SettingsCommunitySortType,
    regionId: RegionId,
    searchInstance?: CommunitySearchInstance,
): CommunitySearchInitializeAction => ({
    type: Actions[Actions.COMMUNITY_SEARCH_INITIALIZE],
    sort,
    regionId,
    key,
    searchInstance,
});

export const communitySearchResetEvent = (
    key: string,
    sort: SettingsCommunitySortType,
    regionId: RegionId,
): CommunitySearchInitializeAction => ({
    type: Actions[Actions.COMMUNITY_SEARCH_CLEAR],
    sort,
    regionId,
    key,
});

export const communitySearchMapResetEvent = (key: string): CommunitySearchAction => ({
    type: Actions[Actions.COMMUNITY_SEARCH_MAP_RESET],
    key,
});

export const communitySearchNextPage = (key: string): CommunitySearchAction => ({
    type: Actions[Actions.COMMUNITY_SEARCH_NEXT_PAGE],
    key,
});

export const communitySearchPreviousPage = (key: string): CommunitySearchAction => ({
    type: Actions[Actions.COMMUNITY_SEARCH_PREV_PAGE],
    key,
});

export const communitySearchGoToPage = (
    key: string,
    pageNumber: number,
): CommunitySearchGoToPageAction => ({
    type: Actions[Actions.COMMUNITY_SEARCH_GO_TO_PAGE],
    pageNumber,
    key,
});

export const communitySearchSetPageSize = (
    key: string,
    pageSize: number,
): CommunitySearchSetPageSizeAction => ({
    type: Actions[Actions.COMMUNITY_SEARCH_SET_PAGE_SIZE],
    pageSize,
    key,
});

export const communitySearchUpdateSearch = (
    key: string,
    searchInstance: CommunitySearchInstance,
): CommunitySearchUpdateSearchAction => ({
    type: Actions[Actions.COMMUNITY_SEARCH_UPDATE_SEARCH],
    searchInstance,
    key,
});
