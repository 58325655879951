import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import { CheckIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { ReactNode, useState } from "react";
import { SecondaryButton } from "../secondary-button";

type ButtonsParams = { close: () => void };

type ButtonsCallback = (params: ButtonsParams) => ReactNode;

export interface AlertProps {
    title: string;
    onDismiss?: () => void;
    type?: "success" | "warning";
    buttons?: ButtonsCallback;
    children?: ReactNode;
}

export const Alert: React.FC<AlertProps> = props => {
    const { title, onDismiss, children, type, buttons } = props;
    const [open, setOpen] = useState(true);

    const close = React.useCallback(() => {
        setOpen(false);
        if (onDismiss) {
            onDismiss();
        }
    }, [onDismiss, setOpen]);

    return (
        <Transition show={open}>
            <Dialog as="div" className="relative z-1500" onClose={close}>
                <TransitionChild
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed z-1500 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <DialogPanel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div
                                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${
                                            type === "success"
                                                ? "bg-green-100"
                                                : "bg-red-100"
                                        } sm:mx-0 sm:h-10 sm:w-10`}>
                                        {type === "success" ? (
                                            <CheckIcon
                                                className="h-6 w-6 text-green-500"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <ExclamationTriangleIcon
                                                className="h-6 w-6 text-red-600"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <DialogTitle
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900">
                                            {title}
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {children}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    {buttons ? (
                                        buttons({ close })
                                    ) : (
                                        <SecondaryButton onClick={close}>
                                            OK
                                        </SecondaryButton>
                                    )}
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
