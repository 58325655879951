import { CheckIcon } from "@heroicons/react/20/solid";
import {
    ExclamationTriangleIcon,
    InformationCircleIcon,
} from "@heroicons/react/24/outline";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";

interface InlineBannerProps {
    type: "success" | "warning" | "error" | "info";
    showIcon?: boolean;
    fontStyle?: "bold" | "normal" | "semibold";
    children: React.ReactNode;
}

const className = (type: InlineBannerProps["type"]): string => {
    if (type === "success") {
        return "bg-green-100 text-green-700";
    }
    if (type === "warning") {
        return "bg-yellow-100 text-yellow-800";
    }
    if (type === "error") {
        return "bg-red-100 text-red-600";
    }
    if (type === "info") {
        return "bg-brand-100 text-brand-600";
    }
    throw new Error(`Unkown type ${type}`);
};

export const InlineBanner: React.FC<InlineBannerProps> = props => {
    const { type, children } = props;
    const showIcon = typeof props.showIcon === "boolean" ? props.showIcon : true;
    const fontStyle = props.fontStyle || "bold";

    return (
        <div
            className={`${className(
                type,
            )} font-${fontStyle} p-2 md:px-4 lg:p-4 flex items-begin`}>
            <div className="flex-0">
                {showIcon ? (
                    type === "success" ? (
                        <CheckIcon className="w-4 h-4 mt-1" />
                    ) : type === "info" ? (
                        <InformationCircleIcon className="w-4 h-4 mt-1" />
                    ) : (
                        <ExclamationTriangleIcon className="w-4 h-4 mt-1" />
                    )
                ) : null}
            </div>
            <div className="flex-1 ml-1 md:ml-2">{children}</div>
        </div>
    );
};

interface InlineTransientBannerOwnProps {
    timeout?: number;
    children: React.ReactNode;
}

export interface InlineTransientBannerRef {
    show: () => void;
}

const InlineTransientBanner = forwardRef<
    InlineTransientBannerRef,
    InlineTransientBannerOwnProps & InlineBannerProps
>((props, ref) => {
    const [visible, setVisible] = useState(false);
    const { timeout, ...rest } = props;
    const realTimeout = timeout || 3000;
    const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (visible) {
            timeoutIdRef.current = setTimeout(() => {
                setVisible(false);
            }, realTimeout);
        }
        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [visible, realTimeout]);

    useImperativeHandle(ref, () => ({
        show: () => setVisible(true),
    }));

    return (
        <div
            className={`${
                visible ? "opacity-100" : "opacity-0"
            } transition-opacity ease-in duration-75`}>
            <InlineBanner {...rest} />
        </div>
    );
});

InlineTransientBanner.displayName = "InlineTransientBanner";
export { InlineTransientBanner };
