import honeybadger from "@honeybadger-io/js";
import { getServerInfo } from "./server";

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
let instance: any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
const delayedContext: any[] = [];

export const configureErrorTracking = async () => {
    const serverInfo = await getServerInfo();
    instance = honeybadger.configure({
        apiKey: "6502fcd2",
        environment: serverInfo.environment,
        revision: serverInfo.build,
    });
    delayedContext.forEach(x => x(instance));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
(window as any).customReport = () => {
    try {
        throw "this is a test!";
    } catch (e) {
        honeybadger.notify(e);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
(window as any).customReportOnInstance = () => {
    try {
        throw "this is a test!";
    } catch (e) {
        instance.notify(e);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export const setErrorTrackingContext = (context: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const callback = (i: any) => i.setContext(context);
    if (instance && instance.setContext) {
        callback(instance);
    } else {
        delayedContext.push(callback);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export const reportError = (e: Error, context: any) => {
    if (!instance) return;
    instance.notify(e, undefined, { context });
};
