import { RequestState } from "../constants/loading";

export interface RequestDescription {
    started: Date;
    ended: Date;
    status: RequestState;
    correlationId: string;
    details?: string;
    eventType?: string;
    data?: unknown;
}

export const requestSummary = (desc: RequestDescription) => {
    return `CorrelationId: ${desc.correlationId}, Details: ${desc.details}`;
};
