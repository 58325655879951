import { UseQueryOptions } from "react-query";

export function stableQueryOptions<T>(): Partial<UseQueryOptions<T>> {
    return {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
    };
}
