import * as Common from "@sp-crm/core";
import { CommunityId, ITenant } from "@sp-crm/core";
import { createSelector } from "reselect";
import { ClientState } from "store/reducers/clients";
import { SettingsState } from "store/reducers/settings";
import { ApplicationState } from "store/state";
import { PermissionsState } from "../reducers/permissions";
import { UserState } from "../reducers/users";
import {
    getClients,
    getCommunities,
    getCommunitySearches,
    getPermissions,
    getRegion,
    getSettings,
    getTenant,
    getUserState,
    getUsers,
} from "./base";

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
const getCommunityId = (state: any, props: any) => props.match.params.communityId; // TODO this shouldn't be here, should instead be based on community ID
const clientsByCommunity = createSelector(
    getClients,
    getCommunityId,
    (clientState: ClientState, communityId: CommunityId): Common.IClient[] => {
        const clients = Object.keys(clientState.clients)
            .map(k => clientState.clients[k as Common.ClientId])
            .filter(c => !!c);

        return clients
            .filter(c => c.chosenCommunityId === communityId)
            .map(c => Common.Client.load(c));
    },
);

export const getClient = createSelector(
    getClients,
    (_clientState: ApplicationState, clientId: Common.ClientId) => clientId,
    (clientState: ClientState, clientId: Common.ClientId): Common.IClient => {
        return Common.Client.load(clientState.clients[clientId]);
    },
);

const baseAppDataLoaded = createSelector(
    getTenant,
    getUsers,
    getPermissions,
    getSettings,
    (
        tenant: ITenant,
        userState: UserState,
        permissionsState: PermissionsState,
        settingsState: SettingsState,
    ) =>
        !!tenant &&
        userState.usersLoadedOnce &&
        permissionsState.permissionsLoadedOnce &&
        settingsState.legacyQuestionsLoaded,
);

export {
    baseAppDataLoaded,
    clientsByCommunity,
    getClients,
    getCommunities,
    getCommunityId,
    getCommunitySearches,
    getPermissions,
    getRegion,
    getTenant,
    getUserState,
    getUsers,
};
