import { ActionType, WhiteLabelContentMode } from "generated/graphql";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import * as http from "util/http";
import { stableQueryOptions } from "util/requests";
import { useIsAllowed } from "./hooks";

export interface ProductBranding {
    logoUrl: string;
    faviconUrl: string;
    supportEmailAddress: string;
    productName: string;
    contentMode: WhiteLabelContentMode;
}

interface HookResponse {
    branding: ProductBranding | null;
    isLoading: boolean;
}

export const useProductBranding = (): HookResponse => {
    const fetchBranding = useCallback(async () => {
        const response = await http.get(http.newRequestDescription(), "/api/branding");
        return response.data as ProductBranding;
    }, []);

    const query = useQuery("getBranding", fetchBranding, stableQueryOptions());

    const appBranding = useMemo(() => {
        return query.data || null;
    }, [query.data]);

    return {
        branding: appBranding,
        isLoading: query.isLoading,
    };
};

export const useProductName = (): string => {
    const { branding } = useProductBranding();
    return branding?.productName || "";
};

export const useSupportEmailAddress = (): string => {
    const { branding } = useProductBranding();
    return branding?.supportEmailAddress || "";
};

export const useWhiteLabelContentMode = (): WhiteLabelContentMode => {
    const { branding, isLoading } = useProductBranding();
    const override = useIsAllowed(ActionType.ViewRestrictedWhiteLabelContent);

    if (isLoading) {
        return WhiteLabelContentMode.Restricted;
    }

    if (override) {
        return WhiteLabelContentMode.Full;
    }

    return branding?.contentMode || WhiteLabelContentMode.Restricted;
};
