import { parseEntityId, TenantId, UserId } from "@sp-crm/core";

interface IUserRecord {
    email: string;
    id: UserId;
    tenantId: TenantId;
}

export interface ILoginResponse {
    token: string;
    userRecord: IUserRecord;
}

export interface LoginCredentials {
    email: string;
    password: string;
    longTermLogin: boolean;
}

const TOKEN_KEY = "authToken";
const USER_ID_KEY = "userId";
let lastId = 0;

export function getUniqueKey(): string {
    lastId += 1;
    return `${Date.now()}-${lastId}`;
}

export function handleLogin(payload: ILoginResponse) {
    setUserId(payload.userRecord.id);
}

export function getUserId(): UserId | null {
    if (window.localStorage[USER_ID_KEY]) {
        return parseEntityId<UserId>(window.localStorage[USER_ID_KEY]);
    }
    return null;
}

function setUserId(userId: UserId) {
    window.localStorage[USER_ID_KEY] = userId;
}

export function clearCredentials() {
    window.localStorage[TOKEN_KEY] = "";
    window.localStorage[USER_ID_KEY] = "";
}

export function setFavicon(path: string | undefined | null): void {
    if (!path) {
        return;
    }
    const link = document.getElementById("favicon");
    if (link) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        (link as any).href = path;
    }
}

export function setPageTitle(title: string) {
    document.title = title;
}

export function copyToClipboard(text: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    let isSuccess: any = false;

    const element = document.createElement("input");
    element.style.padding = "0";
    element.style.border = "none";
    element.style.boxShadow = "none";
    element.style.outline = "none";
    element.value = text;

    document.body.appendChild(element);
    element.focus();
    element.select();

    try {
        isSuccess = document.execCommand("copy");
    } catch (e) {
        console.error("copy to clipboard error", e);
        isSuccess = e;
    }

    document.body.removeChild(element);
    return isSuccess;
}

const advancedTabKey = "CRM_ADVANCED_MODE";

export const inAdvancedMode = () => {
    return localStorage[advancedTabKey] === "true";
};

export const enabledAdvancedMode = () => {
    localStorage[advancedTabKey] = "true";
};

export const disableAdvancedMode = () => {
    localStorage[advancedTabKey] = "false";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
(window as any).crmAdvanced = {
    enable: () => (localStorage[advancedTabKey] = "true"),
    disable: () => (localStorage[advancedTabKey] = "false"),
};

export const setBrowserValue = (key: string, value: string) => {
    window.sessionStorage.setItem(key, value);
    window.localStorage.setItem(key, value);
};

export const getBrowserValue = (key: string): string | null => {
    return window.sessionStorage.getItem(key) || window.localStorage.getItem(key);
};

let userTz: string | null = null;
const getUserTz = (): string => {
    if (userTz) {
        return userTz;
    }
    userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTz;
};

export const extraRequestHeaders = (): { [key: string]: string } => {
    return {
        "x-crm-client-timezone": getUserTz(),
    };
};
