export function mapToArray<T>(objects: { [id: string]: T }): T[] {
    const returnVal: T[] = [];
    for (const key in objects) {
        if (Object.prototype.hasOwnProperty.call(objects, key)) {
            returnVal.push(objects[key]);
        }
    }
    return returnVal;
}

export function splitToColumns<T>(elements: T[]): T[][] {
    elements = elements || [];
    const midpoint = Math.ceil(elements.length / 2);
    return [elements.slice(0, midpoint), elements.slice(midpoint)];
}

export const stringHash = (input: string): string => {
    let hash = 0;
    let i;
    let chr;
    if (input.length === 0) {
        return hash.toString();
    }
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
};
