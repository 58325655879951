import { MagnifyingGlassCircleIcon } from "@heroicons/react/20/solid";
import * as React from "react";

interface Props {
    label?: string | JSX.Element;
}
export const SearchInput: React.FC<
    Props & React.InputHTMLAttributes<HTMLInputElement>
> = props => {
    const { className, label, ...rest } = props;
    return (
        <div className={className ?? ""}>
            <label>
                {label ? <div className="mb-1">{label}</div> : null}
                <div className="relative rounded-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MagnifyingGlassCircleIcon className="text-gray-200 h-6 w-6" />
                    </div>
                    <span className="box flex-1">
                        <input
                            className="form-input block rounded-sm md:rounded w-full disabled:bg-gray-100 border-gray-300 pl-10 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                            {...rest}
                        />
                    </span>
                </div>
            </label>
        </div>
    );
};
