import { get, newRequestDescription } from "./http";

interface ServerInfo {
    environment: string;
    build: string;
}

let serverInfo = null as ServerInfo | null;

export const getServerInfo = async (): Promise<ServerInfo> => {
    if (serverInfo) return serverInfo;
    const status = await get(newRequestDescription(), "/api/health");
    serverInfo = status.data as ServerInfo;
    return serverInfo;
};
