import { RunningFeatures } from "@sp-crm/core";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/state";

interface FeaturePropsFromState {
    name: string;
    enabled: boolean;
}

interface FeatureInputProps {
    name: RunningFeatures;
    default?: boolean;
    children?: React.ReactNode;
}

type FeatureProps = FeaturePropsFromState & FeatureInputProps;

class Feature extends React.Component<FeatureProps, undefined> {
    render() {
        if (this.props.enabled && this.props.children) {
            return this.props.children;
        }
        return null;
    }
}

function mapFeatureStateToProps(
    state: ApplicationState,
    ownProps: FeatureInputProps,
): FeaturePropsFromState {
    return {
        name: ownProps.name,
        enabled: isFeatureEnabled(state, ownProps.name, ownProps.default),
    };
}

const featureControl = connect(mapFeatureStateToProps)(Feature);
export { antiFeatureControl as AntiFeature, featureControl as Feature };

function mapAntiFeatureStateToProps(
    state: ApplicationState,
    ownProps: FeatureInputProps,
): FeaturePropsFromState {
    const val = mapFeatureStateToProps(state, ownProps);
    val.enabled = !val.enabled;
    return val;
}

const antiFeatureControl = connect(mapAntiFeatureStateToProps)(Feature);

export function isFeatureEnabled(
    state: ApplicationState,
    name: RunningFeatures,
    defaultVal?: boolean,
): boolean {
    let isEnabled = defaultVal || false;
    if (state && state.tenant && state.tenant.tenant && state.tenant.tenant.features) {
        if (Object.prototype.hasOwnProperty.call(state.tenant.tenant.features, name)) {
            isEnabled = state.tenant.tenant.features[name];
        }
    }

    return isEnabled;
}
